import React from "react";

const InfoBlurb = ({ title, body, background }) => {
  return (
    <section
      className={`grid-container padding-gutters intro-cta padding-top-medium padding-bottom-small background__${
        background ? background : "none"
      }`}
    >
      <h2>{title}</h2>
      {body.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </section>
  );
};

export default InfoBlurb;
