import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import "./App.css";
import "./App.scss";
import "./WH.css";
import Header from "./components/Header";
import InfoBlurb from "./components/InfoBlurb";
import ButtonGrid from "./components/ButtonGrid";
import ButtonGridMobile from "./components/ButtonGridMobile";
import Footer from "./components/Footer";
import MessageBox from "./components/MessageBox";
import Jotform from "./components/Jotform";

function App() {
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [mobile, set_mobile] = useState(
    window.innerWidth < 1025 ? true : false
  );
  const [navigation, set_navigation] = useState("main");
  const [title, set_title] = useState("");
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [image, set_image] = useState("/welcome.jpg");
  const [image_style, set_image_style] = useState({});
  const [email_taken, set_email_taken] = useState(null);
  const [countdown, set_countdown] = useState(null);
  const [error, set_error] = useState(null);
  const [invalid, set_invalid] = useState(null);
  const form = "https://form.jotform.com/231363117816049";

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const firstname = query.get("firstname");
    const lastname = query.get("lastname");
    const email = query.get("email");

    if (!firstname || !lastname || !email) set_invalid(true);

    set_first_name(firstname);
    set_last_name(lastname);
    set_email(email);
    email !== null && email_check(email);
  }, []);

  useEffect(() => {
    if (invalid || navigation === "email_thank_you") {
      setTimeout(
        () => window.location.replace("https://wolseyhalloxford.org.uk"),
        5000
      );
    }
  }, [invalid, navigation]);

  const top = useRef(null);

  window.addEventListener("resize", () => {
    set_window_width(window.innerWidth);
  });

  useEffect(() => {
    console.log(window_width);
    if (window_width < 1025 && !mobile) set_mobile(true);
    else if (window_width >= 1025 && mobile) set_mobile(false);
  }, [window_width]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const first_name = query.get("firstname");

    if (invalid) {
      set_title(`Something went wrong!`);
      set_image("/welcome.jpg");
      set_image_style({ height: "200%" });
    } else if (!email_taken) {
      set_title(`Hi ${first_name}!`);
      set_image("/welcome.jpg");
      set_image_style({ height: "200%" });
    } else if (navigation === "main") {
      set_title(`Welcome back ${first_name}!`);
      set_image("/welcome.jpg");
      set_image_style({ height: "200%" });
    } else if (navigation === "contact_admissions") {
      set_title("Contact admissions");
      set_image("/admissions 3.jpg");
      set_image_style({ height: "105%" });
    } else if (navigation === "contact_team") {
      set_title("Contact team");
      set_image("/admissions 3.jpg");
      set_image_style({ height: "105%" });
    } else if (navigation === "jotform") {
      set_title("Long Enquiry Form");
    } else if (navigation === "email_thank_you") {
      set_title("Thank you for your enquiry!");
      set_image("/Thank you 2.jpg");
    }

    window.scrollTo({
      top: top.current.offsetTop,
      behavior: "instant",
    });
  }, [navigation, email_taken, invalid]);

  const button_title = "Reconnect with our team";
  const buttons = [
    {
      title: "Already enrolled with Wolsey Hall Oxford?",
      text: [
        "If you are already enrolled with Wolsey Hall Oxford please click below to access the Parent Portal.",
        "If you have forgotten your password please use the reset password button below.",
      ],
      image: "/family.jpg",
      image_style: {},
      buttons: [
        {
          title: "Login",
          link: "https://wolseyhalloxford.openapply.com/parents/sign_in",
        },
        {
          title: "Reset Password",
          link: "https://wolseyhalloxford.openapply.com/parents/password/new",
        },
      ],
    },
    {
      title: "Have you previously completed an enquiry form but not enrolled?",
      text: [
        "If you have completed an enquiry form in the past, but not enrolled, please click here and our Admissions team will be in touch soon to help with your new enquiry.",
      ],
      image: "/applicant.jpg",
      image_style: { objectPosition: "0 -20px" },
      buttons: [
        {
          title: "Contact Admissions",
          link: "#top",
          trigger: "contact_admissions",
        },
      ],
    },
    {
      title: "Have you previously enrolled on our courses?",
      text: [
        "If you or your child have previously been enrolled on our courses but are no longer an active student please click below to contact our team.",
      ],
      image: "/graduated.jpg",
      image_style: { objectPosition: "0 -20px" },
      buttons: [
        {
          title: "Contact Team",
          link: "#top",
          trigger: "contact_team",
        },
      ],
    },
  ];

  // EMAIL CHECKER
  useEffect(() => {
    if (email_taken === false) {
      setTimeout(
        () =>
          window.location.replace(
            `${form}?email=${email}&parentName[first]=${first_name}&parentName[last]=${last_name}`
          ),
        200
      );

      set_countdown(1);
    }
    // eslint-disable-next-line
  }, [email_taken]);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => set_countdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      window.location.replace(
        `${form}?email=${email}&parentName[first]=${first_name}&parentName[last]=${last_name}`
      );
    }
    // eslint-disable-next-line
  }, [countdown]);

  const email_check = async (email) => {
    try {
      const res = await axios.get(
        `https://wolseyhalloxford.openapply.com/roi/email_taken?email=${email}`
      );

      if (res.data) {
        console.log(res.data.taken);
        set_email_taken(res.data.taken);
      } else {
        set_error("something went wrong");
      }
    } catch (error) {
      set_error(error.message);

      if (process.env.REACT_APP_MODE === "dev") {
        set_email_taken(true);
      }
    }
  };

  return (
    <div ref={top}>
      <Header
        title={title}
        img={image}
        img_style={image_style}
        first_name={first_name}
        set_navigation={set_navigation}
      />

      {invalid ? (
        <InfoBlurb
          title={`Looks like you shouldn't be here right now.`}
          body={[`Please wait while we redirect you back to our website.`]}
        />
      ) : email_taken === null ? (
        <InfoBlurb
          title={`We're just checking if you're already registered with Wolsey Hall.`}
          body={[
            `Just give us a minute and we'll redirect you where you need to go.`,
          ]}
        />
      ) : email_taken === false ? (
        <InfoBlurb
          title={`You're all set! Let's get you signed up.`}
          body={[`We will redirect you to our long enquiry form in 5 seconds.`]}
        />
      ) : navigation === "main" ? (
        <div>
          <main id="main">
            {mobile ? (
              ""
            ) : (
              <InfoBlurb
                title={`It looks like you are already registered with Wolsey Hall Oxford!`}
                body={[
                  `In order to progress your enquiry please look at the options below and click on the one that applies to you.`,
                ]}
              />
            )}
            {mobile ? (
              <ButtonGridMobile
                set_navigation={set_navigation}
                title={button_title}
                buttons={buttons}
              />
            ) : (
              <ButtonGrid
                set_navigation={set_navigation}
                title={button_title}
                buttons={buttons}
              />
            )}
          </main>
        </div>
      ) : navigation === "contact_admissions" ||
        navigation === "contact_team" ? (
        <div>
          <InfoBlurb
            title={`Get back in touch with our ${
              navigation === "contact_admissions" ? "admissions team" : "team"
            }!`}
            body={
              navigation === "contact_admissions"
                ? [
                    `If you have previously enquired and now want to progress your enquiry, please use the box below to
                    send a message to our team and they will be back in touch with you very soon.`,
                  ]
                : [
                    `If you or your child has previously been a student with Wolsey Hall but are not currently enrolled,
                    please use the box below to send a message to our team. They will be back in touch with you very
                    soon.`,
                  ]
            }
            background="white"
          />
          <MessageBox
            title={
              navigation === "contact_admissions"
                ? "Contact admissions"
                : "Contact team"
            }
            body={"Send a message to our team!"}
            background={"beige"}
            set_navigation={set_navigation}
            email={email}
            firstname={first_name}
            lastname={last_name}
            type={navigation === "contact_admissions" ? "enquiry" : "returning"}
          />
        </div>
      ) : navigation === "email_thank_you" ? (
        <div>
          <InfoBlurb
            title={`Thank you!`}
            body={[
              `Thank you for your enquiry, our team will be in touch very soon. We will now redirect you back to our website.`,
            ]}
            background="beige"
          />
        </div>
      ) : (
        ""
      )}
      <Footer />
      {navigation !== "main" && navigation !== "email_thank_you" ? (
        <div
          id="wpfront-scroll-top-container"
          style={{
            opacity: "0.8",
            right: "20px",
            top: "20px",
            display: "block",
          }}
          onClick={() => set_navigation("main")}
        >
          <img
            src="https://s6r4t4f7.stackpathcdn.com/wp-content/plugins/wpfront-scroll-top/images/icons/36.png"
            alt=""
            style={{
              width: "35px",
              height: "35px",
              transform: "rotate(270deg)",
            }}
          />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
