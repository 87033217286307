import React from "react";

const ButtonGridMobile = ({ set_navigation, title, buttons }) => {
  return (
    <div className="grid-container padding-gutters flexible-row background__white">
      <div
        className="grid-x grid-padding-x grid-padding-y"
        data-equalizer=""
        data-equalize-on-stack="true"
        data-equalize-by-row="true"
        data-resize="uos1gk-eq"
        data-mutate="bh0ghc-eq"
        data-t="xb5v98-t"
        data-events="mutate"
      >
        <div class="cell small-12 section__title text-center">
          <h2>{title}</h2>
        </div>

        {buttons.map((button, index) => (
          <div className="mobile__button">
            {" "}
            <div
              className="mobile__button__image"
              style={{
                backgroundImage: `url(${button.image})`,
              }}
            >
              {/* <img
                src={button.image}
                class="info-tile__image"
                alt="Lower secondary fees"
                decoding="async"
                loading="lazy"
              />{" "} */}
            </div>
            <div class="mobile__button__content" style={{ width: "100%" }}>
              <h4 class="info-tile__title" style={{ minHeight: "3.5rem" }}>
                {button.title}
              </h4>

              {button.text.map((text, index) => (
                <p key={index} class="info-tile__text">
                  {text}
                </p>
              ))}

              <div
                class="button-container"
                style={{
                  textAlign: "center",
                  display: button.buttons.length > 1 ? "flex" : "block",
                }}
              >
                {button.buttons &&
                  button.buttons.map((b, index) => (
                    <a
                      class={`es-button green`}
                      href={b.link}
                      target=""
                      key={index}
                      style={{
                        width: button.buttons.length > 1 ? "40%" : "100%",
                        minWidth: "0px",
                        display: "inline-block",
                        marginTop: "15px",
                        marginRight: button.buttons.length > 1 ? "5%" : "0",
                        marginLeft: button.buttons.length > 1 ? "5%" : "0",
                        paddingTop: "25px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();

                        b.trigger && set_navigation(b.trigger);

                        if (b.link.includes("https")) {
                          window.open(b.link, "_blank");
                        }
                      }}
                    >
                      {b.title}
                    </a>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ButtonGridMobile;
