import React from "react";

const ButtonGrid = ({ set_navigation, title, buttons }) => {
  return (
    <div>
      <div
        className="grid-container padding-gutters flexible-row background__white"
        style={{ paddingBottom: "20px" }}
      >
        <div
          className="grid-x grid-padding-x grid-padding-y"
          data-equalizer=""
          data-equalize-on-stack="true"
          data-equalize-by-row="true"
          data-resize="uos1gk-eq"
          data-mutate="bh0ghc-eq"
          data-t="xb5v98-t"
          data-events="mutate"
        >
          <div class="cell small-12 section__title text-center">
            <h2>{title}</h2>
          </div>

          {buttons.map((button, index) => (
            <div
              class={`cell small-12 medium-6 large-${
                index === 0 ? "auto" : "4"
              } info-tile`}
              data-equalizer-watch=""
              key={index}
              style={{
                paddingBottom: "0px",
              }}
            >
              {" "}
              <div class="info-tile__image-container">
                <img
                  width="590"
                  height="372"
                  src={button.image}
                  class="info-tile__image"
                  alt="Lower secondary fees"
                  decoding="async"
                  loading="lazy"
                  sizes="(max-width: 590px) 100vw, 590px"
                  style={button.image_style}
                />{" "}
              </div>
              <div class="content" style={{ paddingBottom: "0px" }}>
                <h4 class="info-tile__title" style={{ minHeight: "3.5rem" }}>
                  {button.title}
                </h4>

                {button.text.map((text, index) => (
                  <p key={index} class="info-tile__text">
                    {text}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className="grid-x grid-padding-x grid-padding-y"
          data-equalizer=""
          data-equalize-on-stack="true"
          data-equalize-by-row="true"
          data-resize="uos1gk-eq"
          data-mutate="bh0ghc-eq"
          data-t="xb5v98-t"
          data-events="mutate"
        >
          {" "}
          {buttons.map((button, index) => (
            <div
              class={`cell small-12 medium-6 large-${
                index === 0 ? "auto" : "4"
              } info-tile`}
              data-equalizer-watch=""
              key={index}
              // style={{ height: "588px" }}
            >
              <div
                class="button-container"
                style={{
                  textAlign: "center",
                  display: button.buttons.length > 1 ? "flex" : "block",
                  position: "relative",
                }}
              >
                {button.buttons &&
                  button.buttons.map((b, index) => (
                    <a
                      class={`es-button green`}
                      href={b.link}
                      target={b.link.includes("https") ? "_blank" : "_self"}
                      key={index}
                      style={{
                        width: button.buttons.length > 1 ? "50%" : "100%",
                        display: "inline-block",
                        marginRight:
                          index === 0 && button.buttons.length > 1 ? "4%" : "0",
                        maxWidth: button.buttons.length > 1 ? "50%" : "100%",

                        // marginLeft:
                        //   index === 1 && button.buttons.length > 1 ? "5%" : "0",
                        paddingTop:
                          window.innerWidth < 1470 && index === 1
                            ? "18px"
                            : "25px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();

                        b.trigger && set_navigation(b.trigger);

                        if (b.link.includes("https")) {
                          window.open(b.link, "_blank");
                        }
                      }}
                    >
                      {b.title}
                    </a>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ButtonGrid;
