import axios from "axios";
import React, { useState, useEffect } from "react";

const MessageBox = ({
  title,
  body,
  background,
  set_navigation,
  firstname,
  lastname,
  email,
  type,
}) => {
  const [message, set_message] = useState("");
  const [token, set_token] = useState("");
  const [disabled, set_disabled] = useState(false);

  useEffect(() => {
    get_token();
  }, []);

  useEffect(() => {
    console.log(token);
  }, [token]);

  const get_token = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EMAILER_URL}/api/v1/token`
      );

      set_token(res.data.token);
    } catch (error) {
      console.error("!!!");
    }
  };

  const send_message = async () => {
    set_disabled(true);
    axios.post(`${process.env.REACT_APP_EMAILER_URL}/api/v1/email`, {
      token,
      firstname,
      lastname,
      email,
      message,
      type,
    });

    set_navigation("email_thank_you");
  };

  return (
    <section
      className={`message grid-container padding-gutters intro-cta background__${
        background ? background : "none"
      }`}
    >
      <form className="message__form">
        <label htmlFor="message" className="message__form__title">
          {title}
        </label>
        <textarea
          id="message"
          name="message"
          rows="6"
          cols="50"
          placeholder={body}
          value={message}
          onChange={(e) => set_message(e.target.value)}
          disabled={disabled}
        ></textarea>
        <div class="button-container message__form__button">
          <a
            class="es-button green"
            target=""
            onClick={() => {
              !disabled && send_message();
            }}
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          >
            {title}
          </a>
        </div>
      </form>
    </section>
  );
};

export default MessageBox;
