import React from "react";

const Footer = () => {
  return (
    <footer class="grid-container padding-gutters background__dark-blue">
      <div class="grid-x align-middle footer__top padding-vertical-medium">
        <div class="small-12 large-6 cell">
          <a class="footer__logo " href="https://wolseyhalloxford.org.uk/">
            <img
              src="https://wolseyhalloxford.org.uk/wp-content/themes/wolsey-2021/assets/svg/logo_light.svg"
              class="logo"
              loading="lazy"
              alt="Wolsey Hall Logo"
              width="528"
              height="89"
            />
          </a>
        </div>
      </div>

      <div class="grid-x footer__bottom">
        <div class="small-12 cell footer__site-by text-center">
          <p></p>
          <p>
            Wolsey Hall Oxford International Ltd,&nbsp;Midland House, West Way,
            Oxford, OX2 0PH.
            <br />
            Registered in England No. 9270091
          </p>
          <p></p>
        </div>
        <div class="small-12 cell footer__site-by text-center">
          <p>
            Wolsey Hall Website by{" "}
            <a
              href="https://www.electricstudio.co.uk"
              title="Digital Agency Oxford"
              class="es"
              target="_blank"
            >
              <span>Electric Studio</span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
