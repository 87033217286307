import React from "react";

const Header = ({ title, img, img_style, first_name, set_navigation }) => {
  return (
    <header id="top">
      <div className="header has-image">
        <div className="header__top">
          <div className="grid-container header__top--wrapper">
            <div className="grid-x align-justify header__top--logo-actions">
              <div className="cell small-12 medium-shrink header__logo--wrapper">
                <a
                  className="header__logo "
                  href={
                    process.env.REACT_APP_MODE === "dev"
                      ? "/"
                      : "https://wolseyhalloxford.org.uk/"
                  }
                >
                  <img
                    src="/logo.png"
                    className="logo"
                    alt="Wolsey Hall Logo"
                    loading="eager"
                    width="528"
                    height="89"
                    style={{ width: "528px", height: "89px" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "90%",
              margin: "auto",
              height: "4px",
              backgroundColor: "#384d69",
            }}
          ></div>
        </div>
        <div className="auto header__bottom">
          <div className="header__featured-image" style={img_style}>
            <img
              width="1640"
              height="920"
              src={img}
              className="attachment-featured size-featured wp-post-image"
              alt=""
              decoding="async"
              loading="eager"
              sizes="(max-width: 1640px) 100vw, 1640px"
            />{" "}
          </div>

          <div className="grid-container padding-gutters header__content-wrapper">
            <div className="grid-x align-center">
              <div className="cell small-12 header__content text-left">
                <div className="breadcrumbs">
                  <span>
                    <span>
                      <a href="https://wolseyhalloxford.org.uk/">Home</a>
                    </span>{" "}
                    {!title.includes("Welcome") &&
                    !title.includes("Thank") &&
                    !title.includes("Hi ") &&
                    title !== "" ? (
                      <span>
                        <a href="#" onClick={() => set_navigation("main")}>
                          Welcome back {first_name}!
                        </a>
                      </span>
                    ) : (
                      ""
                    )}
                    <span className="breadcrumb_last" aria-current="page">
                      {title}
                    </span>
                  </span>{" "}
                </div>
                <h1 className="title">{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
